<template>
  <el-container>
    <el-main>
      <login-bar />
      <router-view />
      <el-row class="movie-list">
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px" />
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px" />
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px; text-align: center">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>注册帐号</span>
              </div>
              <div class="text item">
                <el-form ref="form" :model="userRegistry" label-width="100px">
                  <el-form-item label="邮箱/手机号" label-width="100px">
                    <el-input
                      v-model="userRegistry.principal"
                      placeholder="请输入邮箱或手机号"
                      style="width: 45%; padding-right: 10px"
                      clearable
                    />
                    <el-button :disabled="isBtn" @click="registerVerifyCode">{{ code }}</el-button>
                  </el-form-item>
                  <el-form-item label="验证码" label-width="90px">
                    <el-input
                      v-model="userRegistry.verifyCode"
                      placeholder="请输入短信验证码"
                      style="padding-right: 1px"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="密码">
                    <el-input
                      v-model="userRegistry.credential"
                      type="password"
                      placeholder="请输入密码"
                      style="padding-right: 1px"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="图形验证码" label-width="90px">
                    <el-image :src="captchaCode" @click="getCaptcha" />
                    <el-input
                      v-model="userRegistry.captchaCode"
                      placeholder="请输入图形验证码"
                      style="width: 50%; padding-right: 10px"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      :loading="isLoading"
                      @click.native="registerBtn"
                    >注册</el-button>
                    <el-button type="plain" @click="login">返回登录</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import LoginBar from 'components/layout/LoginBar'

export default {
  name: 'Register',
  components: {
    LoginBar
  },
  mixins: [userMixin],
  watch: {
    $route() {
      this.$router.go()
    }
  },
  created() {
    document.title = '注册帐号'
    this.fetchPubkey(2)
  },
  data() {
    return {
    }
  },
  methods: {
    login() {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
